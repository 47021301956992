<script>
  import { project, translations } from "$lib/services/store";

  export let text = null;

  export let lang = $project.settings.lang ?? "en";

  function translate(text, lang) {
    if ($translations[text]) {
      return $translations[text][lang] || text
    }
    return text
  }

  let translated = translate(text, lang);
</script>

{translated}